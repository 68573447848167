import React from 'react'
import { Text, Spacer } from "@nextui-org/react";
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

export default function DaasText() {

  const target = [
    "1. Tráenos tu desafío.",
    "2. Match perfecto.",
    "3. Estimado en horas de  desarollo.",
    "4. Adquiere tu paquete de horas (20,40,80 horas).",
    "5. Líder gestiona el proyecto y el equipo de IA.",
    "6. Sprint con resultados/avances cada 15 días."
  ]

  return (
    <>
      <div style={{ width: "100vw", background: "#000025", height: "100vh" }}>
        <ScrollAnimation animateIn="animate__fadeInLeft" duration={2}>
          <div>
            <Text h2 style={{ position: "relative", display: "flex", justifyContent: 'center' }} weight="bold" css={{
              textGradient: "45deg, $purple500 20%, $pink500 60%",
            }}>¿Como?</Text>
            <div className='DaasContain' style={{ display: "flex" }}>
              <ul>
                <li><Text h3 css={{
                  textGradient: "45deg, $purple500 20%, $pink500 60%",
                }}>1</Text><Spacer x={0.5} /><Text h4>Tráenos tu desafío.</Text></li>
                <li><Text h3 css={{
                  textGradient: "45deg, $purple500 20%, $pink500 60%",
                }}>2</Text><Spacer x={0.5} /><Text h4>Match perfecto.</Text></li>
                <li><Text h3 css={{
                  textGradient: "45deg, $purple500 20%, $pink500 60%",
                }}>3</Text><Spacer x={0.5} /><Text h4>estimado en horas de desarollo.</Text></li>
                <li><Text h3 css={{
                  textGradient: "45deg, $purple500 20%, $pink500 60%",
                }}>4</Text><Spacer x={0.5} /><Text h4>Adquiere tu paquete de horas (20,40,80 horas).</Text></li>
                <li><Text h3 css={{
                  textGradient: "45deg, $purple500 20%, $pink500 60%",
                }}>5</Text><Spacer x={0.5} /><Text h4>Líder gestiona el proyecto y el equipo de IA.</Text></li>
                <li><Text h3 css={{
                  textGradient: "45deg, $purple500 20%, $pink500 60%",
                }}>6</Text><Spacer x={0.5} /><Text h4>Sprint con resultados/avances cada 15 días.</Text></li>
              </ul>
            </div>
          </div >
        </ScrollAnimation>
      </div></>

  )
}