import React from "react";
import { Card, Text, Avatar, Spacer, Container, Row, Grid } from "@nextui-org/react";
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import "./TeacherCard.css";

const TeacherCard = ({ name, src, features, fbsrc, igsrc, lisrc, twsrc }) => {
    return (
        <Card css={{
            backgroundColor: "#FFFFFF10",
            borderRadius: "0px",
            width: "100%"
        }}>
            <Grid.Container>
                <Grid xs={4}>
                    <Avatar
                        bordered
                        color={"gradient"}
                        css={{
                            margin: "20px",
                            justifySelf: "center",
                            alignSelf: "center",
                            width: "100%",
                            height: "auto",
                        }}
                        src={src}
                    />
                </Grid>
                <Spacer x={2} />
                <Grid xs={7}>
                    <Container gap={0}>
                        <Row>
                            <Text css={{
                                fontSize: "$lg",
                                letterSpacing: "2px",
                                fontWeight: "$bold",
                                textAlign: "center",
                                fontFamily: "inter"
                            }}>
                                {name}
                            </Text>
                        </Row>
                        <Spacer y={0.2} />
                        <Row>
                            <div style={{
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <Spacer x={1} />
                                {
                                    fbsrc &&
                                    <>
                                        <BsFacebook className="course-logo" size={32} onClick={() => window.open(fbsrc)} />
                                        <Spacer x={2} />
                                    </>
                                }
                                {
                                    igsrc &&
                                    <>
                                        <BsInstagram className="course-logo" size={32} onClick={() => window.open(igsrc)} />
                                        <Spacer x={2} />
                                    </>
                                }
                                {
                                    twsrc &&
                                    <>
                                        <BsTwitter className="course-logo" size={32} onClick={() => window.open(twsrc)} />
                                        <Spacer x={2} />
                                    </>
                                }
                                {
                                    lisrc &&
                                    <BsLinkedin className="course-logo" onClick={() => window.open(lisrc)} size={32} />
                                }
                            </div>
                        </Row>
                        <Spacer y={0.5} />
                        <Row>
                            <div>
                                {
                                    features.map(feature =>
                                        <>
                                            <Text css={{ fontFamily: "inter" }} size={"$md"}>
                                                - &nbsp; {feature}
                                            </Text>
                                            <Spacer y={0.2}/>
                                        </>
                                    )
                                }
                            </div>
                        </Row>
                    </Container>
                </Grid>
            </Grid.Container>
        </Card>
    )
}

export default TeacherCard;