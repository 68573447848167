import React from "react";
import { Card, Container, Text, Button, Spacer, Avatar, Col, Grid, Row, Divider, Modal } from "@nextui-org/react";
import { AiOutlineArrowRight } from "react-icons/ai";
import TeacherCard from "./TeacherCard";
import "./NewCourseModal.css";
import bgg_photo from "../../src/bgg_photo.jpeg";

const NewCourseModal = ({ modalOpen, onClose, courseName, courseDesc }) => {

    const bgg_features = [
        "Chief Scientist Officer",
        "Founder myfuture-AI"
    ]

    return (
        <Modal
            closeButton
            open={modalOpen}
            onClose={onClose}
            blur
            width="1000px"
            css={{
                height: "auto",
                backgroundColor: "#000015",
                boxShadow: "0px 0px 15px 3px #5e17eb;",
                marginBottom: "2vh",
            }}
        >
            <Modal.Header css={{ justifyContent: "center", flexDirection: "column" }}>
                <Text
                    weight="bold"
                    css={{
                        fontSize: "$xl",
                        textGradient: "45deg, $purple500 20%, $pink500 60%",
                        fontFamily: "inter",
                        letterSpacing: "0.2px",
                    }}
                >
                    Ya está disponible
                </Text>
                <Container css={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Text css={{
                        fontSize: "$lg",
                        justifyContent: "center",
                        letterSpacing: "0.2px",
                        fontFamily: "inter"
                    }}>
                        Nuevo curso
                    </Text>
                    <Spacer x={1} />
                    <Text css={{
                        fontSize: "$lg",
                        justifyContent: "center",
                        letterSpacing: "0.4px",
                        fontFamily: "inter",
                        fontWeight: "bold"
                    }}>
                        {courseName}
                    </Text>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Container>
                            <Text size={"$md"} css={{ fontFamily: "inter" }}>
                                {courseDesc}
                            </Text>
                            <Spacer y={1} />
                            <Row justify="center">
                                <TeacherCard
                                    name={"Benjamín González"}
                                    src={bgg_photo}
                                    features={bgg_features}
                                    lisrc={"https://www.linkedin.com/in/bgg-ai/"}
                                />
                            </Row>
                            <Spacer y={1} />
                            <Row justify="center">
                                <Button
                                    auto
                                    bordered
                                    color="secondary"
                                    onClick={() => window.open("https://myfuture-ai.thinkific.com/courses/UnaPizcaDeIA")}
                                    css={{
                                        alignItems: "center",
                                        height: "125px",
                                        color: "white",
                                        boxShadow: "0px 3px #AD007C, 0px 2px #AD007C, 0px 0px #AD007C",
                                        border: "1px solid $pink500",
                                        '&:hover': {
                                            boxShadow: "0px 0px 10px 4px #AD007C",
                                            border: "1px solid $pink500"
                                        }
                                    }}
                                    iconRight={
                                        <AiOutlineArrowRight size={36} onClick={onClose} />
                                    }>
                                    <Text size={"40px"} css={{ fontFamily: "inter", letterSpacing: "0.1px" }}>
                                        Ver Curso
                                    </Text>
                                </Button>
                            </Row>
                        </Container>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal >
    )
}

export default NewCourseModal;
