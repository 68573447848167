import React, { useState, lazy, Suspense, useEffect, useRef } from "react";
import { BsLinkedin } from 'react-icons/bs'
import { GrInstagram } from 'react-icons/gr'
import { SiTwitter } from 'react-icons/si'
import { createTheme, NextUIProvider } from "@nextui-org/react"
import { useMediaQuery } from 'react-responsive'
import { Link as Linky } from "react-scroll";
import ScaleLoader from "react-spinners/ScaleLoader";
import DaasText from "./Routes/ProductsPage/DaasText";
import NewCourseModal from "./Components/NewCourseModal";
import { useIsVisible } from "react-is-visible";

// LAZY IMPORTS
const FrontPage = lazy(() => import('./Routes/FrontPage/FrontPage'));
const Products = lazy(() => import('./Routes/ProductsPage/Products'));
const Clients = lazy(() => import('./Routes/ClientsPage/Clients'));
const Contact = lazy(() => import('./Routes/ContactPage/Contact'));
const PhoneProducts = lazy(() => import('./Routes/ProductsPage/PhoneProducts'));
const ProductText = lazy(() => import('./Routes/ProductsPage/ProductText'));
const PhoneEducation = lazy(() => import('./Routes/Education/PhoneEducation'));
const EducationText = lazy(() => import('./Routes/Education/EducationText'));
const PhoneClientes = lazy(() => import('./Routes/ClientsPage/PhoneClients'));
const PhoneContact = lazy(() => import('./Routes/ContactPage/PhoneContact'));
const PhoneFooter = lazy(() => import('./Components/PhoneFooter'));
const Footer = lazy(() => import('./Components/Footer'));

function App() {

  // _____REACT STATES________
  const [modalOpen, setModalOpen] = useState(true);

  const modalTriggerRef = useRef();
  const modalTriggerVisible = useIsVisible(modalTriggerRef);

  // _____MEDIA RESPONSIVE________
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  // _____________NEXT UI THEME PROVIDER____
  const FutureTheme = createTheme({
    type: "dark",
    theme: {
      colors: {
        // brand colors
        primaryLight: '#3A7BD5',
        primary: '#5e17eb',
        primaryDark: '#102647',
      }
    }
  })

  // ____ PAGE LOADER______


  const renderLoader = () =>
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#000025"
      }}
    >
      <ScaleLoader color="white" size={500} />
    </div>;

  return (
    <Suspense fallback={renderLoader()}>
       // ___TABLET OR MOVILE
      <>{isTabletOrMobile && <nav id={"NavbarPhone"}>
        <li><a href="#inicio" style={{ cursor: 'pointer' }}><img src='https://diagnosemlpdf.s3.us-east-2.amazonaws.com/landing/static+source/logos/myfutureai_blanco_logo.png' style={{ width: "200px", height: "auto", padding: "0", margin: "0" }} alt="Logo" /></a></li>
      </nav>}

        {isDesktopOrLaptop && <nav id="Navbar">
          <div style={{ width: "100%", display: 'flex', flexDirection: "row" }}>
            <ul style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", width: "100%" }}>
              <li><a href="#inicio" style={{ cursor: 'pointer' }}><img src='https://diagnosemlpdf.s3.us-east-2.amazonaws.com/landing/static+source/logos/myfutureai_blanco_logo.png' style={{ width: "200px", height: "auto", padding: "0", margin: "0", marginTop: '3vh' }} alt="Logo" /></a></li>
              <li>
                <Linky
                  activeClass="active"
                  className="inicio"
                  to="inicio"
                  spy={true}
                  smooth={true}
                  duration={0}
                >
                  Inicio
                </Linky>
              </li>
              <li>
                <Linky
                  activeClass="active"
                  className="productos"
                  to="productos"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Productos
                </Linky>
              </li>
              <li>
                <Linky
                  activeClass="active"
                  className="clientes"
                  to="clientes"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Clientes
                </Linky>
              </li>
              <li>
                <Linky
                  activeClass="active"
                  className="Contacto"
                  to="Contacto"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Contacto
                </Linky>
              </li>
              <li>
                <li style={{ position: 'relative', top: '10' }} className="icons"><a href='https://myfutureai.medium.com/' rel="preconnect" target="_blank">Blog</a></li>
              </li>
            </ul>
            <ul style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", width: "20%" }}>
              <li style={{ display: "flex", alignItems: "center", marginTop: '0.7vh' }} className="icons"><a href='https://www.linkedin.com/company/myfutureai/' rel="preconnect" target="_blank"><BsLinkedin /></a></li>
              <li style={{ display: "flex", alignItems: "center", marginTop: '0.7vh' }} className="icons"><a href='https://www.instagram.com/myfuture_ai/' rel="preconnect" target="_blank"><GrInstagram /></a></li>
              <li style={{ display: "flex", alignItems: "center", marginTop: '0.7vh' }} className="icons"><a href='https://twitter.com/myfuture_ai' rel="preconnect" target="_blank"><SiTwitter /></a></li>
            </ul>
          </div>
        </nav>}



        <NextUIProvider theme={FutureTheme}>
          <section name="inicio" className="element">
            <FrontPage />
          </section>
          <section name="productos" className="element">
            <Products />
          </section>
          {isTabletOrMobile &&
            <>
              <section id='phoneText' style={{ scrollMarginTop: "100px" }}>
                <DaasText />
              </section>
              <section>
                <PhoneProducts />
              </section>
              <section id='productText' style={{ background: "#000025", height: "60vh", scrollMarginTop: "100px" }}>
                <ProductText />
              </section>
              <section >
                <PhoneEducation />
              </section>
              <section id="phoneeduText" style={{ scrollMarginTop: "100px" }}>
                <EducationText />
              </section>
              <section style={{ marginTop: "5vh", height: "auto", scrollMarginTop: "100px" }}>
                <PhoneClientes />
              </section>
              <section>
                <PhoneContact />
              </section>
              <section style={{ width: "100%", height: "auto", scrollMarginTop: "100px" }}>
                <PhoneFooter />
              </section>
            </>
          }
          {isDesktopOrLaptop &&
            <>
              <section ref={modalTriggerRef} name="clientes" className="element">
                <Clients />
              </section>
              <section name="Contacto" className="element">
                <Contact />
              </section>
              <section style={{ width: "100%", height: "auto", scrollMarginTop: "50px" }}>
                <Footer />
              </section>
            </>
          }
          {modalTriggerVisible &&
            <NewCourseModal
              modalOpen={modalOpen}
              onClose={() => { setModalOpen(false) }}
              courseName={"Una pizca de IA"}
              courseDesc={"Conviértete en un conocedor de IA y aplícala junto a:"}
            />
          }
        </NextUIProvider>
      </>
    </Suspense>
  )

}

export default App;