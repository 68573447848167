import { configureStore } from '@reduxjs/toolkit'
import getInputReducer from '../Features/GetIdInputsSlice'


// SETTING  GLOBAL STORE WITH REDUX

export const store = configureStore({
  reducer: {

    // PASSING REDUCER TO THE STORE - THEY WILL MAKE THE CHANGES FROM THE STATUS
    //--- ACTUAL NAME FROM THE REDUCER(DISABLE) :  *NEW NAME* FROM  THE REDUCER IMPORTED FROM ITS SLICE
    idGetter:  getInputReducer,


  },
})

