import { createSlice } from "@reduxjs/toolkit";

// INTIAL STATES FOR FRO INPUTS STORED IN REDUX

const initialState = {
    inputname:"",
    inputEmail:"",
    inputCompany:"",
    inputPhone:"",
    inputMessage:""
}

// SLICE WITH REDUCERS USED TO MAKE CHANGES TO THE INITIAL STATE

export const GetIdInputsSlice = createSlice({
 name:'idGetter',
 initialState,
     reducers:{
       savename:(state, action) =>{
        state.inputname = action.payload
      },
      saveemail:(state, action) =>{
        state.inputEmail = action.payload
      },
      savecompany:(state, action) =>{
        state.inputCompany = action.payload
      },
      // inputs form provs
      savephone:(state, action) =>{
        state.inputPhone = action.payload
      },
      savemessage:(state, action) =>{
        state.inputMessage = action.payload
      },
      backinitial:(state)=>{
        state.inputname = ""
        state.inputEmail = ""
        state.inputCompany = ""
        state.inputPhone = ""
        state.inputMessage = ""
      } 
 }
})

// EXPORTING ACTIONS TO APPLY CHANGES OPN EVENT

export const { savename, saveemail, savecompany, savephone ,savemessage, backinitial} = GetIdInputsSlice.actions


export default GetIdInputsSlice.reducer